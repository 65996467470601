import type { RouteLocationRaw } from '#vue-router';

const defaultOptions = (): HomeLayoutOptions => ({
  showBottomNavigation: true,
  activeNavigationItem: 'unspecified',
});

// state のキーを固定する
const useOptionsState = () =>
  useState<Record<string, HomeLayoutOptions>>(() => ({}));

export type HomeLayoutOptions = {
  title?: string;
  backButtonDestination?: RouteLocationRaw;
  showBottomNavigation: boolean;
  activeNavigationItem: NavigationItemType;
};

export type NavigationItemType =
  | ValueOf<typeof navigationItemTypes>
  | 'unspecified';
export const navigationItemTypes = ['home', 'messages', 'misc'] as const;

// showBottomNavigation に false が明示されてないときは activeNavigationItem を
// 必須にする
type Arg =
  | (Exclude<
      Partial<HomeLayoutOptions>,
      'showBottomNavigation' | 'activeNavigationItem'
    > & {
      showBottomNavigation: false;
    })
  | (Exclude<
      Partial<HomeLayoutOptions>,
      'showBottomNavigation' | 'activeNavigationItem'
    > & {
      showBottomNavigation?: true;
      activeNavigationItem: Exclude<NavigationItemType, 'unspecified'>;
    });

export const defineHomeLayoutOptions = (
  opt: MaybeRefOrGetter<Arg> | ComputedRef<Arg>
) => {
  const options = computed<HomeLayoutOptions>(() => ({
    ...defaultOptions(),
    ...toValue(opt),
  }));
  const optState = useOptionsState();
  const route = useRoute();
  // reactive を切って define が叩かれた時点の path で固定する
  const path = route.path;
  onBeforeMount(() =>
    watch(
      options,
      (opt) => {
        optState.value = { ...optState.value, [path]: opt };
      },
      { immediate: true }
    )
  );
};

export const useHomeLayoutOptions = () => {
  const route = useRoute();
  const state = useOptionsState();
  return computed(() => state.value[route.path] ?? defaultOptions());
};
